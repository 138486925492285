











































































import { Component, Vue } from "vue-property-decorator";
import ClickOutside from "vue-click-outside";
import { IUser } from "@/types/User";
import { IVersion } from "@/store/generalModule";

@Component({
  directives: {
    ClickOutside,
  },
})
export default class extends Vue {
  navOpen = false;

  get user(): IUser {
    return this.$store.getters["user/user"];
  }

  get versions(): IVersion[] {
    return this.$store.getters["general/versions"];
  }

  logout(): void {
    this.$store.commit("user/LOGOUT");
    this.$router.push({ name: "Login" });
  }

  openChangeVersionsModal(): void {
    if (this.versions.length)
      this.$store.commit("modals/TOGGLE_CHANGE_VERSIONS_MODAL", true);
  }

  closeNav(): void {
    this.navOpen = false;
  }
}
