






import { Component, Vue } from "vue-property-decorator";
import { IAlert } from "@/store/errorModule";
import Item from "@/components/Item.vue";
@Component({
  components: { Item },
})
export default class AlertWrapper extends Vue {
  get alerts(): IAlert[] {
    return this.$store.getters["errors/alerts"];
  }
}
