import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";
import firebase from "firebase/app";
import "firebase/auth";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    meta: { requiresAuth: true },
    component: Home,
  },
  {
    path: "/login",
    name: "Login",
    meta: { requiresLogout: true },
    component: () =>
      import(/* webpackChunkName: "Login" */ "../views/Login.vue"),
  },
  {
    path: "/register",
    name: "Register",
    meta: { requiresLogout: true },
    component: () =>
      import(/* webpackChunkName: "Register" */ "../views/Register.vue"),
  },
  {
    path: "/user",
    name: "UserHome",
    meta: { requiresAuth: true },
    component: () =>
      import(/* webpackChunkName: "UserHome" */ "../views/User/Index.vue"),
    children: [
      {
        path: ":id",
        name: "UserDetail",
        component: () =>
          import(/* webpackChunkName: "User" */ "../views/User/Detail.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const currentUser = firebase.auth().currentUser;
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  if (requiresAuth && !currentUser) {
    next({ name: "Login" });
  } else next();
});

export default router;
