import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/assets/scss/main.scss";
import firebase from "firebase/app";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faChevronDown,
  faEye,
  faEyeSlash,
  faLock,
  faMinus,
  faPencilAlt,
  faPlus,
  faTimes,
  faUserSecret,
} from "@fortawesome/free-solid-svg-icons";
import Button from "@/components/shared/Button.vue";

library.add(
  faPencilAlt,
  faUserSecret,
  faPlus,
  faTimes,
  faMinus,
  faEye,
  faEyeSlash,
  faLock,
  faChevronDown
);

Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("c-button", Button);

Vue.config.productionTip = false;
const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
};

firebase.initializeApp(firebaseConfig);

let app: Vue;

const init = () => {
  if (!app) {
    app = new Vue({
      router,
      store,
      render: (h) => h(App),
    }).$mount("#app");
  }
};

firebase.auth().onAuthStateChanged(async (user) => {
  if (user) {
    store.commit("user/SET_USER", user);
  } else {
    store.commit("user/SET_USER", null);
  }

  init();
});
