import Vue from "vue";
import Vuex from "vuex";
import cardsModule from "@/store/cardsModule";
import modalModule from "@/store/modalModule";
import errorModule from "@/store/errorModule";
import apiModule from "@/store/apiModule";
import userModule from "@/store/userModule";
import generalModule from "@/store/generalModule";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    cards: cardsModule,
    errors: errorModule,
    general: generalModule,
    user: userModule,
    modals: modalModule,
    api: apiModule,
  },
});
