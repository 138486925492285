


















































































































































import { Component, Vue } from "vue-property-decorator";

@Component
export default class extends Vue {
  private title = "";

  get projectTitle(): string {
    return this.$store.getters["cards/projectTitle"];
  }

  setProjectTitle(): void {
    this.$store.dispatch("cards/setProjectTitle", this.title);
    this.$store.commit("cards/SET_TITLE_MODAL", false);
    this.resetForm();
  }

  onCancelClick(): void {
    this.$store.commit("cards/SET_TITLE_MODAL", false);
  }

  resetForm(): void {
    this.title = "";
  }
}
