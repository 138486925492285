











import { Component, Vue } from "vue-property-decorator";
import ModalAskTitle from "./components/modal/ModalAskTitle.vue";
import NavbarVue from "./components/Navbar.vue";
import AlertWrapper from "@/components/alerts/AlertWrapper.vue";

@Component({
  components: {
    AlertWrapper,
    NavbarVue,
    ModalAskTitle,
  },
})
export default class extends Vue {}
