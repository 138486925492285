import { Module } from "vuex";

interface IState {
  addCardModal: boolean;
  addPredictionModal: boolean;
  changePredictionModal: boolean;
  changeVersionsModal: boolean;
  addVersionModal: boolean;
}

const modalModule: Module<IState, never> = {
  namespaced: true,

  state: () => ({
    addCardModal: false,
    addPredictionModal: false,
    changePredictionModal: false,
    changeVersionsModal: false,
    addVersionModal: false,
  }),

  mutations: {
    TOGGLE_ADD_CARD_MODAL: (state, payload) => {
      state.addCardModal = payload;
    },

    TOGGLE_ADD_PREDICTION_MODAL: (state, payload) => {
      state.addPredictionModal = payload;
    },

    TOGGLE_CHANGE_PREDICTION_MODAL: (state, payload) => {
      state.changePredictionModal = payload;
    },

    TOGGLE_ADD_VERSION_MODAL: (state, payload) => {
      state.addVersionModal = payload;
    },

    TOGGLE_CHANGE_VERSIONS_MODAL: (state, payload) => {
      state.changeVersionsModal = payload;
    },
  },

  getters: {
    addCardModal: (state) => state.addCardModal,
    addPredictionModal: (state) => state.addPredictionModal,
    changePredictionModal: (state) => state.changePredictionModal,
    addVersionModal: (state) => state.addVersionModal,
    changeVersionsModal: (state) => state.changeVersionsModal,
  },
};

export default modalModule;
