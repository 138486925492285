


























import { Component, Prop, Vue } from "vue-property-decorator";
import { IAlert } from "@/store/errorModule";

@Component
export default class AlertItem extends Vue {
  @Prop({ required: true })
  private readonly item!: IAlert;

  get backgroundColor(): string {
    switch (this.item.errorType) {
      case "ERROR":
        return "bg-red-500";
      case "DANGER":
        return "bg-yellow-500";
      default:
        return "bg-green-500";
    }
  }

  removeAlert(): void {
    this.$store.commit("errors/CLEAR_ALERT", this.item.id);
  }
}
