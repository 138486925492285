import { Module } from "vuex";
import { v4 as uuidv4 } from "uuid";

interface IErrors {
  percentage: { errors: Array<string> };
  title: { errors: Array<string> };
}

type alertType = "ERROR" | "SUCCESS" | "DANGER";

export interface IAlert {
  id: string;
  text: string;
  errorType: alertType;
}

interface IState {
  errors: IErrors;
  alerts: IAlert[];
}

const errorModule: Module<IState, never> = {
  namespaced: true,

  state: () => ({
    errors: {
      percentage: { errors: [] },
      title: { errors: [] },
    },
    alerts: [],
  }),

  mutations: {
    ADD_ERROR_TITLE: (state: IState, payload) => {
      state.errors.title.errors = [payload];
    },

    ADD_ERROR_PERCENTAGE: (state: IState, payload: string) => {
      state.errors.percentage.errors = [payload];
    },

    CLEAR_ERRORS: (state: IState) => {
      state.errors.percentage.errors = [];
      state.errors.title.errors = [];
    },

    ADD_ALERT: (
      state: IState,
      payload: { text: string; errorType: alertType }
    ) => {
      const alert = { ...payload, id: uuidv4() };
      state.alerts = [...state.alerts, alert];

      setTimeout(() => {
        state.alerts = state.alerts.filter((a) => a.id !== alert.id);
      }, 6000);
    },

    CLEAR_ALERT: (state: IState, payload: string) => {
      state.alerts = state.alerts.filter((alert) => alert.id !== payload);
    },
  },

  getters: {
    titleErrors: (state) => state.errors.title,
    percentageErrors: (state) => state.errors.percentage,
    alerts: (state) => state.alerts,
  },
};

export default errorModule;
