import { Module } from "vuex";
import { IUser } from "@/types/User";
import firebase from "firebase/app";
import "firebase/auth";
import router from "@/router";

interface IState {
  user: IUser | null;
  emailAfterRegister: string;
}

const userModule: Module<IState, never> = {
  namespaced: true,

  state: () => ({
    user: null as IUser | null,
    emailAfterRegister: "",
  }),

  mutations: {
    SET_USER: (state, payload) => {
      state.user = payload;
    },

    LOGOUT: async (state) => {
      await firebase.auth().signOut();
      state.user = null;
      await router.push({ name: "Login" });
    },
  },

  actions: {
    login: async ({ commit }, { email, password }) => {
      try {
        await firebase.auth().signInWithEmailAndPassword(email, password);
      } catch (e) {
        console.log(e);
      }
    },

    register: async ({ commit }, { email, password }) => {
      try {
        await firebase.auth().createUserWithEmailAndPassword(email, password);
        await router.push({ name: "Home" });
        commit(
          "errors/ADD_ALERT",
          {
            errorType: "SUCCESS",
            text: "User successfully created.",
          },
          { root: true }
        );
      } catch (e) {
        commit(
          "errors/ADD_ALERT",
          {
            errorType: "ERROR",
            text: e.message,
          },
          { root: true }
        );
      }
    },

    signInWithGoogle: async () => {
      const provider = new firebase.auth.GoogleAuthProvider();
      provider.setCustomParameters({
        display: "popup",
      });

      try {
        await firebase.auth().signInWithPopup(provider);
        await router.push({ name: "Home" });
      } catch (e) {
        console.log(e);
      }
    },

    signInWithFacebook: async () => {
      const provider = new firebase.auth.FacebookAuthProvider();
      provider.addScope("public_profile");
      provider.setCustomParameters({
        display: "popup",
      });

      try {
        await firebase.auth().signInWithPopup(provider);
        await router.push({ name: "Home" });
      } catch (e) {
        console.log(e);
      }
    },
  },

  getters: {
    user: (state) => state.user,
    isLoggedIn: (state) => !!state.user,
    userEmail: (state) => state.emailAfterRegister,
  },
};

export default userModule;
