





































import { Component, Vue } from "vue-property-decorator";
import { IUser } from "@/types/User";

@Component
export default class Home extends Vue {
  projectTitle = "";

  get user(): IUser {
    return this.$store.getters["user/user"];
  }

  async setProjectTitle(): Promise<void> {
    await this.$store.dispatch("cards/setProjectTitle", this.projectTitle);

    await this.$router.push({
      name: "UserDetail",
      params: { id: this.user.uid },
    });
  }
}
