import { IItem } from "@/types/Item";
import { IPrediction } from "@/types/Prediction";
import { v4 as uuidv4 } from "uuid";
import { Module } from "vuex";

interface IAddCard {
  cardTitle: string;
  cardPercentage: string;
}

interface IState {
  projectTitle: string;
  totalPercentage: number;
  cards: IItem[];
  predictions: IPrediction[];
}

function updateCardsForPercentage(
  cards: Array<IItem>,
  totalPercentage: number
) {
  return cards.map((card, index) => {
    if (index === 0) {
      card.result = totalPercentage * 100 * card.percentage;
    } else {
      card.result = cards[index - 1].result * card.percentage;
    }
    card.index = uuidv4();
    return card;
  });
}

const cardsModule: Module<IState, never> = {
  namespaced: true,

  state: {
    projectTitle: "",
    totalPercentage: 1,
    cards: [],
    predictions: [],
  },

  mutations: {
    SET_PROJECT_TITLE: (state, payload) => {
      if (payload) {
        state.projectTitle = payload;
        localStorage.setItem("PROJECT_TITLE", payload);
        document.title = `Probatunity | ${payload}`;
      } else {
        document.title = "Probatunity";
      }
    },

    CHANGE_CARD_TITLE: (state, payload) => {
      const card = state.cards.find((card) => card.index === payload.index);
      if (card) {
        card.title = payload.title;
      }
    },

    CHANGE_CARD_PERCENTAGE: (state, payload) => {
      const cardsCopy = [...state.cards];
      const card = cardsCopy.find((card) => card.index === payload.index);
      if (card) {
        card.percentage = payload.percentage;
        const updatedCards = updateCardsForPercentage(
          cardsCopy,
          state.totalPercentage
        );
        state.cards = [...updatedCards];
      }
    },

    UPDATE_CARDS: (state, payload) => {
      state.cards = payload;
      updateCardsForPercentage(state.cards, state.totalPercentage);
    },

    ADD_USER_PREDICTION: (state, payload) => {
      state.predictions.push(payload);
    },

    ADD_USER_PREDICTION_BULK: (state, payload) => {
      state.predictions = payload;
    },

    ADD_CARD: (state, payload) => {
      if (state.cards.length === 0) {
        state.cards.push({
          ...payload,
          result: state.totalPercentage * 100 * payload.percentage,
        });
      } else {
        state.cards.push(payload);
        const index = state.cards.findIndex(
          (card) => card.index === payload.index
        );
        state.cards[index].result =
          state.cards[index - 1].result * payload.percentage;
      }
    },

    ADD_CARD_BULK: (state, payload) => {
      state.cards = payload;
    },

    DELETE_CARD: (state, payload) => {
      state.cards = payload;
    },

    DELETE_ALL_CARDS: (state) => {
      state.cards = [];
    },

    DELETE_ALL_PREDICTIONS: (state) => {
      state.predictions = [];
    },

    DELETE_PREDICTION: (state, payload) => {
      state.predictions = payload;
    },

    REMOVE_EMPTY_PREDICTIONS: (state) => {
      state.predictions = state.predictions.filter((p) => p.name);
    },
  },

  actions: {
    setProjectTitle: ({ commit }, payload: string) => {
      commit("SET_PROJECT_TITLE", payload);
    },

    changeCardTitle: ({ commit, state }, payload) => {
      commit("CHANGE_CARD_TITLE", payload);
      commit("general/UPDATE_CARDS", state.cards, { root: true });
    },

    changeCardPercentage: ({ commit, state }, payload) => {
      commit("CHANGE_CARD_PERCENTAGE", payload);
      commit("general/UPDATE_CARDS", state.cards, { root: true });
    },

    addUserPrediction: ({ commit, dispatch, state }, payload) => {
      commit("ADD_USER_PREDICTION", { id: uuidv4(), ...payload });
      dispatch("general/changePredictions", state.predictions, { root: true });
    },

    addCard: ({ commit, state }, payload: IAddCard) => {
      const card = {
        index: uuidv4(),
        title: payload.cardTitle,
        percentage: Number(payload.cardPercentage) / 100,
      };
      commit("ADD_CARD", card);
      commit("general/UPDATE_CARDS", state.cards, { root: true });
    },

    deleteCard: async ({ commit, dispatch, state }, payload) => {
      const cards = state.cards.filter((card) => card.index !== payload);
      const updatedCards = updateCardsForPercentage(
        cards,
        state.totalPercentage
      );

      await dispatch("general/changeCards", updatedCards, { root: true });
      commit("DELETE_CARD", updatedCards);
    },

    updateCardsOrder: ({ commit, state }, payload) => {
      const cards = updateCardsForPercentage(payload, state.totalPercentage);
      commit("UPDATE_CARDS", cards);
      commit("general/UPDATE_CARDS", cards, { root: true });
    },

    updatePredictions: ({ commit, state }) => {
      commit("general/UPDATE_PREDICTIONS", state.predictions, { root: true });
    },

    deletePrediction: async ({ commit, dispatch, state }, payload) => {
      const predictions = state.predictions.filter(
        (prediction) => prediction.id !== payload
      );

      await dispatch("general/changePredictions", predictions, { root: true });
      commit("DELETE_PREDICTION", predictions);
    },
  },

  getters: {
    projectTitle: (state) => state.projectTitle,
    totalPercentage: (state) => state.totalPercentage,
    cards: (state) => state.cards,
    lastCard: (state) => state.cards[state.cards.length - 1],
    predictions: (state) => state.predictions,
  },
};

export default cardsModule;
