import { Module } from "vuex";
import { IItem } from "@/types/Item";
import { IPrediction } from "@/types/Prediction";
import { v4 as uuidv4 } from "uuid";

export interface IVersion {
  id: string;
  name: string;
  cards: IItem[];
  predictions: IPrediction[];
}

interface IState {
  versions: IVersion[];
  currentVersion: IVersion;
}

const generalModule: Module<IState, never> = {
  namespaced: true,

  state: () => ({
    versions: [],
    currentVersion: {} as IVersion,
  }),

  mutations: {
    ADD_NEW_VERSION: (state: IState, payload) => {
      state.versions.push(payload);
      state.currentVersion = payload;
    },

    CHANGE_CURRENT_VERSION: (state: IState, payload) => {
      state.currentVersion = payload;
    },

    UPDATE_CARDS: (state: IState, payload) => {
      state.currentVersion.cards = payload;
    },

    UPDATE_PREDICTIONS: (state, payload) => {
      state.currentVersion.predictions = payload;
    },

    UPDATE_VERSIONS: (state: IState, payload) => {
      const index = state.versions.findIndex(
        (version) => version.id === payload.id
      );
      state.versions[index] = payload;
    },

    DELETE_VERSIONS: (state) => {
      state.versions = [];
    },

    DELETE_CURRENT_VERSION: (state) => {
      state.currentVersion = {} as IVersion;
    },

    DELETE_VERSION: (state, payload) => {
      state.versions = state.versions.filter(
        (version) => version.id !== payload
      );

      if (state.versions.length) state.currentVersion = state.versions[0];
      else
        state.currentVersion = {
          id: uuidv4(),
          cards: [],
          name: "New prediction",
          predictions: [],
        };
    },
  },

  actions: {
    addNewVersion: ({ commit }, payload) => {
      const cards = payload.cards.map((card: IItem) => ({
        ...card,
        index: uuidv4(),
      }));
      const predictions = payload.predictions.map(
        (prediction: IPrediction) => ({
          ...prediction,
          index: uuidv4(),
        })
      );

      commit("ADD_NEW_VERSION", { ...payload, id: uuidv4() });
      commit("cards/ADD_CARD_BULK", cards, {
        root: true,
      });
      commit("cards/ADD_USER_PREDICTION_BULK", predictions, {
        root: true,
      });
    },

    changeCurrentVersion: ({ commit, state }, payload) => {
      const current = state.versions.find(
        (version) => version.id === payload
      ) as IVersion;
      const cards = current.cards.map((card) => ({ ...card, index: uuidv4() }));
      const predictions = current.predictions.map((prediction) => ({
        ...prediction,
        index: uuidv4(),
      }));

      commit("CHANGE_CURRENT_VERSION", current);
      commit("cards/ADD_CARD_BULK", cards, {
        root: true,
      });
      commit("cards/ADD_USER_PREDICTION_BULK", predictions, {
        root: true,
      });
    },

    changeCards: ({ commit, state }, payload) => {
      commit("UPDATE_CARDS", payload);
      commit("UPDATE_VERSIONS", state.currentVersion);
    },

    changePredictions: ({ commit, state }, payload) => {
      commit("UPDATE_PREDICTIONS", payload);
      commit("UPDATE_VERSIONS", state.currentVersion);
    },

    deleteVersion: ({ commit }, payload) => {
      commit("DELETE_VERSION", payload);
      commit("modals/TOGGLE_CHANGE_VERSIONS_MODAL", false, { root: true });
    },
  },

  getters: {
    versions: (state) => state.versions,
    currentVersion: (state) => state.currentVersion,
  },
};

export default generalModule;
